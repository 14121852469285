import React, { Component } from "react"
import { graphql } from "gatsby"
import axios from "axios"
import ContextConsumer from "../services/context"
import ReleaseListContianer from "../components/releaseList/releaseListContainer"
import { getToken } from "../utils/getToken"
import NewsletterForm from "../components/newsletterForm.js"


export const query = graphql`
  {
    allModules(filter: {view: {slug: {eq: "store"}}}) {
      edges {
        node {
          type
          title
          id
          itemId
        }
      }
    }
  }
`

class Store extends Component {
  state = {
    loading: true,
    error: false,
    contentItems: [],
    authTokenFetched: false,
    fetchedContentItems: false,
    email: '',
  }

  componentDidMount() {
    this.fetchContentItems()
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault();

    axios.post('https://api.sendinblue.com/v3/contacts', {
      "email": this.state.email,
      "listIds":[37,11],
    },
    {
      headers: {
        accept: 'application/json', 
        'Content-Type': 'application/json',
        "api-key": process.env.SENDINBLUE_API,
      }
    }).then(function (response) {
      //handle success
      console.log('email posted:', response)
    }).catch(function (response) {
      //handle error
      console.log('error', response);
    })
    
  }

  render() {
  
    let releaseList = <ReleaseListContianer 
      releases={this.state.contentItems}
      title="Store"
      readyToLoad={false}
      releaseType={"contentItem"}
    />

    if ( this.state.authTokenFetched && this.state.contentItems.length > 1 ) {
      releaseList = <ReleaseListContianer 
        releases={this.state.contentItems}
        title="Store"
        readyToLoad={this.state.fetchedContentItems}
        releaseType={"contentItem"}
      />
    }
    
    return (
      <>
        <div className="m-6 mb-8">
          <div className="uppercase text-2xl md:text-4xl lg:text-4xl xl:text-4xl">Sign up for 10% off</div>
          <NewsletterForm />
        </div>
        {releaseList}
      </>

    )

  }

 
  fetchContentItems = async() => {
    getToken().then(options => {
      this.setState({
        authTokenFetched: true,
      })

      const allModules = this.props.data.allModules
      allModules.edges.map( module => {
        const moduleId = module.node.itemId
        axios 
        .get('https://api.ochre.io/v1/cms/modules/' + moduleId + '/content-items?image_size=l', options)
        .then(response => {
          this.setState({
            loading: false,
            contentItems: response.data.results,
            fetchedContentItems: true
          })
        })
        .catch(error => {
          this.setState({ loading: false, error })
        })

  
      })

    }) 
  }


}
Store.contextType = ContextConsumer;
export default Store
